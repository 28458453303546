import React from "react"
import LayoutComponent from "../../components/Layout.js"
import RoentgenCT from "../../components/RoentgenCT.js"
import Knap from "../../components/Knap.js"

const RoentgenPage = () => (
    <LayoutComponent>
        <h1>Røntgen og CT</h1>
        <p>I samarbeid med våre leverandører kan vi levere nesten alt av røntgen- og CT tilbehør. Vi kan blant annet hjelpe med røntgenbeskyttelse og blybeskyttelse i form av røntgenforkle, blyforkle, blyskjold, blyavdekning, gonadebeskyttere eller røntgenbriller, samt levere røntgentilbehør til posisjonering, kvalitetskontroll (QA), dosimeter, dosimetri, skanning, skanningsutstyr, billeddiagnostikk, kalibrering, markeringer og mammografi.</p>
        <p>I tillegg er vi leveringsdyktige innenfor fantomer, treningsfantomer, vevssimulering og pasientsimulering.</p>
        <h1>Våre leverandører</h1>
        <RoentgenCT />    
        <Knap />
    </LayoutComponent>
)
export default RoentgenPage